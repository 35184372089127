@tailwind base;
@tailwind components;
@tailwind utilities;

/*
//
//
// Fonts
//
//
*/

@font-face {
  font-family: 'PrimaryFont';
  src: url('/fonts/PPMonumentExtended-Book.woff2') format('woff2'),
       url('/fonts/PPMonumentExtended-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HeadingFont';
  src: url('/fonts/PPMonumentExtended-BoldItalic.woff2') format('woff2'),
       url('/fonts/PPMonumentExtended-BoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SubHeadingFont';
  src: url('/fonts/PPMonumentExtended-Regular.woff2') format('woff2'),
       url('/fonts/PPMonumentExtended-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  scroll-padding-top: 100px;
}

body {
  @apply font-primary;
}

h1, h3 {
  @apply font-heading;
}

h2, h4, h5, h6 {
  @apply font-subHeding;
}

p {
  @apply font-primary;
}

:root {
  --cleanit_blue: theme(colors.cleanit_blue);
  --background: theme(colors.background);
  --secondary: theme(colors.secondary);
  --accent: theme(colors.accent);
  --border: theme(colors.border);
}


h1{
  font-size: 2.2rem;
  font-weight: 500;
  /*color: #90F0FF; /* ice bue */
  color: var(--cleanit_blue)
}

h2{
  font-size: 1.7rem;
  color: #F3F7F9;
}
h3{
  font-size: 1.2rem;
  color: #F3F7F9;
}


/* Medium Devices (Tablets)  */
@media screen and (max-width: 991px) {
  h1{
    font-size: 2rem;
  }
  
  h2{
    font-size: 1.3rem;
  }

  p{
    font-size: 0.8rem;
  }
}

/* Small Devices (Phones)  */
@media screen and (max-width: 767px) {
  h1{
    font-size: 2rem;
  }
  
  h2{
    font-size: 1rem;
  }
  p{
    font-size: 0.7rem;
  }
}

/* Extra small Devices (Phones)  */
@media screen and (max-width: 479px) {

}

/*
//
//
// Navbar
//
//
*/

.navbar {
  /* background-color: rgba(28, 41, 114, 0.7); */
  background-color: var(--secondary);
  opacity: 0.95;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 35px;
  width: calc(100% - 40px);
  position: fixed;
  top: 20px;
  left: 20px;
  right: 20px;
  z-index: 1000;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  border-radius: 15px;
}

.navbar-links{
  display: flex;
  gap: 1rem; /* space-x-4 equivalent */
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: 'subHeadingFont';
  padding-right: 20px;
  padding-left: 100px;
}

.navbar-link {
  color: var(--background);
  text-decoration: none;
  align-items: center;
  justify-content: center;
  transition: color 0.3s;
  font-size: 0.8rem;
}

.navbar-link:hover {
  color: var(--cleanit_blue); 
}

.logo{
  text-decoration: none;
  cursor: pointer;
}

.button {
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 1px 20px;
  height: 20px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #F3F7F9;
  color: #1C2972;
  font-size: 0.8rem;
}

.button:hover {
  background-color: #D28A00; /* Darker gold on hover */
  box-shadow: 0 4px 10px rgba(242, 169, 0, 0.4);
  transition: all 0.3s ease;
}

/* Mobile Menu Styles */
.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  height: 3px;
  width: 25px;
  background-color: var(--cleanit_blue);
  margin: 4px 0;
  transition: all 0.3s ease;
}

/* Hamburger Transformation */
.is-active .bar:nth-child(2) {
  opacity: 0;
}

.is-active .bar:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.is-active .bar:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
}

.remove-links{
  display: none;
}

/* Mobile Menu Behavior */
@media screen and (max-width: 991px) {
  
  .computer-links{
    display: none;
  }

  .navbar-toggle {
    display: flex;
    padding-right: 20px;
  }

  .navbar-links{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 3rem; 
    height: 0;
    width: calc(100% - 40px);
    align-items: center;
    justify-content: space-between;
    font-family: 'subHeadingFont';
    z-index: 999;
    top: 60px;
    left: 20px;
    right: 20px;
    overflow: hidden;
    background-color: var(--secondary);
    z-index: 999;
    border-radius: 15px;
    opacity: 0;
    padding-bottom: 25px;
    padding-top: 25px;
    padding-left: 0px;
    padding-right: 0px;
  }

}

/*
//
//
// Landing Section 
//
//
*/


@media screen and (max-width: 1000px) {

  .landing-content{
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 40px;
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .landing-section{
    background-size: 1000px 1000px;
    height: 100vh;
    max-width: 100%;
  }

  .landing-button{
    width: 50%;
  }
}

/*
//
//
// Gallery Section 
//
//
*/

.image-track {
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  gap: 4vmin;
  background: var(--background);
  padding: 50px 0px;
  padding-inline-start: 30px;
  padding-inline-end: 30px;
}

.image-track::-webkit-scrollbar {
  display: none;
}
.imageTrack {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;     /* Firefox */
}


.imageTrack-pic {
  width: 300px;
  height: 350px;
  object-fit: cover;
  background: transparent;
  user-select: none;
  -webkit-touch-callout: none;
  border-radius: 12px;
  border: 2px solid rgba(42, 58, 103, 0.3); /* Soft navy border */

  /* Enhanced blue-tinted shadow for depth */
  box-shadow: 
    0 5px 12px rgba(42, 58, 103, 0.4),  /* Deep navy shadow */
    0 0 12px rgba(106, 212, 255, 0.3),  /* Outer soft blue glow */
    inset 0 0 10px rgba(106, 212, 255, 0.2); /* Inner soft blue highlight */

  /* Smooth hover effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/*
//
//
// Process section
//
//
*/

.process-title{
  font-size: 40px;
}
.process-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  width: 700px;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.process-description{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.process-description h3 {
  color: var(--cleanit_blue);
}
.process-description p {
  color: var(--secondary);
}

.process-line{
  height: 100px;
  width: 5px;
  background-color: var(--cleanit_blue);
  border-radius: 10px;
}

@media screen and (max-width: 767px) {

  .process-title{
    font-size: 25px;
  }

  .process-item{
    gap: 15px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .processImage{
    width: 100px;
  }
}

@media screen and (max-width: 400px) {
  .process-title{
    text-align: center;
  }
  .process-item{
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column-reverse;
    
  }
  .process-description{
    align-items: center;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .process-description p {
    text-align: center;
  }
}


/*
//
//
// Services Section 
//
//
*/

.service-section{
  background-color: #F3F7F9;
}

.service-section-title{
  text-align: center;
  color: #1C2972;
}

.service-div{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  gap: 16px;
  padding: 40px 30px 30px 30px;
}

.service-box{
  position: relative;
  width: 300px;
  height: 350px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #F3F7F9;
  background-color: rgba(28, 41, 114, 1);
  border-radius: 15px;
  padding: 20px;
  border: 1px solid rgba(28, 41, 114, 0.3);
  opacity: 0.85;
  box-shadow: 2px 2px 4px rgba(28, 41, 114, 0.8),
  6px 6px 20px rgba(28, 41, 114, 0.8);
}

.service-list{
  text-align: left;
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 30px;
}

.service-list li {
  margin-bottom: 5px;
  font-size: 13px;
}

.service-title{
  padding-bottom: 5px;
}
.service-text{
  padding-top: 30px;
  font-size: 13px;
}

.price{
  position: absolute; 
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.service-link{
  display: flex;
  margin-top: 15px;
  gap: 4px;
  font-size: '1.1rem';
  cursor: pointer;
  background-color: transparent;
  color: #1C2972;
  align-items: center;
  
}

.service-link-text {
  font-size: 16px;
}

.service-link-open{
  border-top-left-radius: 10px;  /* Top-left corner */
  border-top-right-radius: 10px;
}

.service-link:hover{
  letter-spacing: +2px;

}

.service-dropdown{
  display: none;
  background-color: transparent;
  transition: all 0.3s ease;
  padding: 15px;
}

.service-dropdown-open{
  margin-top: 20px;
  display: block;
  background-color: rgba(0, 254, 240, 0.5);
  color: #1C2972;
  border-radius: 10px;
  border: 1px solid rgba(28, 41, 114, 0.3);
  box-shadow: 2px 2px 4px rgba(28, 41, 114, 0.8),
  6px 6px 20px rgba(0, 254, 240, 0.60);
  transition: all 0.3s ease;
}

.service-dropdown-title{
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.divider {
  position: relative;
  width: 100%; /* Adjust width as needed */
  height: 1px; /* Height of the divider */
  background: transparent; /* Transparent background to show the pseudo-element */
  margin: 30px 0; /* Margin to space the divider from other content */
}

.divider::before {
  content: ""; /* Required to display the pseudo-element */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Full width of the parent (.divider) */
  height: 1px; /* Same height as the parent */
  background: linear-gradient(to right, rgba(0, 254, 240, 0) 0%,#1C2972 50%, rgba(0, 254, 240, 0) 100%); /* Gradient effect */
}

.sevice-p {
  font-size: 11px;
}


@media screen and (max-width: 991px) {

  .service-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 54px;
  }
}

@media screen and (max-width: 767px) {
  .service-link-text{
    font-size: '11px';
  }
}

.cover-image{
  box-shadow: 0px -6px 12px rgba(28, 41, 144, 0.4);

}

/*
//
//
// Form
//
//
*/

.formSection{
  background-color: var(--background);
}

.formContainer{
  background-color: var(--background) ;
}

.form-heading {
  font-size: 3.8rem;
  line-height: 65px;
  padding-top: 20px;
  padding-bottom: 15px;
  color: var(--secondary);
}

.form-image-text {
  font-size: 0.5rem;
}

/* Small Devices (Phones)  */
@media screen and (max-width: 767px) {
  .form-heading{
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 410px) {
  .form-heading{
    font-size: 1.9rem;
  }
}
/*
//
//
// About
//
//
*/
.about-container{
  width: 350px;
  background: 'bg-beige';
  user-select: none;
  -webkit-touch-callout: none;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8), 
  15px 8px 30px rgba(0, 0, 0, 0.60);
}

.about-section{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 96px;
}

@media screen and (max-width: 967px) {
  .about-section{
    flex-direction: column;
  }

  .about-textBox{
    width: 300px;
    font-size: 11px;
  }
}

@media screen and (max-width: 400px) {
  .about-container{
    width: 300px;
  }

  .about-textBox{
    width: 300px;
    font-size: 11px;
  }
}

.about-textBox{
  font-size: 14px;
  width: 300px;
}


/*
//
//
// Footer
//
//
*/

.footer-container{
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  max-width: 100vw;
  background-color:var(--background);
}

.social{
  justify-content: center;
  align-items: center;
  text-align: center;
}

.social-icons{
  font-size: 80px;
  display: flex;
  justify-content: space-evenly;
  color: var(--secondary);
}

.info-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  color: var(--secondary);
}

.footer-heading{
  color: var(--cleanit_blue);
}

@media (max-width: 967px) {
  .footer-container {
    grid-template-columns: 1fr; /* Single column layout */
    grid-template-rows: auto auto; /* Rows will stack vertically */
    justify-items: center; /* Centers items horizontally */
    gap: 10px; /* Optional: Add some gap between the rows */
    width: 100%;
  }
  .social-icons{
    justify-content: space-between;
    gap: 50px;
  }
  .info-container {
    align-items: center;
    text-align: center;
  }
  .info-title {
    padding-bottom: 10px;
  }
}